import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./footer"
import "../assets/stylesheets/layout.scss"
import {ThemeProvider} from 'styled-components'
import theme from '../assets/stylesheets/theme'
import { Helmet } from "react-helmet"
import HeaderExternal from "./headerExternal"
import {getImage} from "gatsby-plugin-image";


const LayoutExternal = ({ children, className}) => {

  const data = useStaticQuery(graphql`query SiteTitleQueryEx {
  site {
    siteMetadata {
      title
    }
  }
  head: file(relativePath: {eq: "assets/images/logohead.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <ThemeProvider theme={theme}>
       <Helmet  title={data.site.siteMetadata.title}>
          <meta name="description" content="Stakctrace" />
          <meta charSet="utf-8" />
      </Helmet>
      <HeaderExternal headImage={getImage(data.head.childImageSharp)}/>
        <main >{children}</main>
      <Footer  className={className}/>
    </ThemeProvider>
  )
}

LayoutExternal.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutExternal
