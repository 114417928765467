import React, { Component } from 'react';
import SocialLinks from './SocialLinks';
import "../assets/stylesheets/headerExternal.scss"
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image";

const Brand = styled(GatsbyImage)`
width: 40px;
height: 40px;
display: block;
`


export default class HeaderExternal extends Component {
  constructor(props) {

    super(props);
    this.state = {
      openMenu: false,
      visibilityClass: '',
      head: props.headImage,
    };
  }

  toggleMenu = value => {
    this.setState({ openMenu: value });
  };
  handleScroll = () => {
    const { visibilityClass } = this.state;
    if (window.pageYOffset > 70) {
      if (visibilityClass !== 'navbar-shrink') {
        this.setState({ visibilityClass: 'navbar-shrink' });
      }
    } else {
      if (visibilityClass === 'navbar-shrink') {
        this.setState({ visibilityClass: '' });
      }
    }
  };


  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { openMenu, visibilityClass, head } = this.state;

    return (
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${visibilityClass} external`}
        id="mainNavEx">
        <div className="container">
          <a className="navbar-brand" href="/">
            <Brand image={head} />
          </a>
          <button
            onClick={_ => this.toggleMenu(!openMenu)}
            className={`navbar-toggler navbar-toggler-right ${openMenu ? '' : 'collapsed'
              }`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div  className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`} id="navbarResponsive" >
            <ul className="navbar-nav mr-auto external">
              <li className="nav-item">
                  <a className="nav-link" href="/#about">
                    QUIÉNES SOMOS
                  </a>
              </li>
              <li className="nav-item">
                  <a className="nav-link" href="/services">
                    SERVICIOS
                  </a>

              </li>
              <li className="nav-item">
                  <a className="nav-link" href="/products">
                    PRODUCTOS
                  </a>
              </li>
              <li className="nav-item">
                  <a className="nav-link" href="/works">
                    TRABAJOS
                  </a>
              </li>
              <li className="nav-item">
                  <a className="nav-link" href="/#jobs">
                    OPORTUNIDADES
                  </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#contact">
                  CONTACTO
                  </a>
              </li>
            </ul>
            {/* <SocialLinks/> */}
          </div>
        </div>
      </nav>
    );
  }
}
